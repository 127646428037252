import * as React from 'react';
import Layout from '../components/Layout';
import { LegalContent } from '../components/StyleGuide/styles';
import Head from '../components/Head';

const Terms = () => {
  return (
    <Layout>
      <Head />
      <LegalContent>
        <h1>Zero One Services Limited - Terms and Conditions</h1>
        <p>
          Please read these terms and conditions carefully before using this
          site
        </p>
        <ol>
          <li>
            <h2>What&rsquo;s in these Terms and Conditions?</h2>
            <ol>
              <li>
                <p>
                  These Terms and Conditions tell you the rules for using our
                  website&nbsp;
                  <a href="https://www.google.com/url?q=https://zero-1.co&amp;sa=D&amp;ust=1534864641840000">
                    https://zero-1.co
                  </a>
                  &nbsp;(&ldquo;our site&rdquo;).
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Who we are and how to contact us</h2>
            <ol>
              <li>
                <p>
                  <a href="https://www.google.com/url?q=https://zero-1.co&amp;sa=D&amp;ust=1534864641841000">
                    https://zero-1.co
                  </a>
                  &nbsp;is a site operated by Zero One Services Limited
                  (&ldquo;We&rdquo; or &ldquo;Us&rdquo;). We are registered in
                  England and Wales under company number 11427372 and have our
                  registered office at Unit 30 Benyon Wharf, 295 Kingsland Road,
                  London, E8 4DQ.
                </p>
              </li>
              <li>
                <p>
                  To contact us, please email{' '}
                  <a href="mailto:hello@zero-1.co">hello@zero-1.co</a>.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>By using our site you accept these Terms and Conditions</h2>
            <ol>
              <li>
                <p>
                  By using our site, you accept these Terms and Conditions and
                  that you agree to comply with them. If you do not agree to
                  these terms, you must not use our site.
                </p>
              </li>
              <li>
                <p>
                  We recommend that you print a copy of these terms for future
                  reference.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>There are other terms that may apply to you</h2>
            <ol>
              <li>
                <p>
                  These Terms and Conditions refer to the following additional
                  terms, which also apply to your use of our site:
                </p>
                <ol>
                  <li>
                    <p>
                      Our Privacy Policy, which sets out the terms on which we
                      process any personal data we collect from you, or that you
                      provide to us. By using our site, you consent to such
                      processing and you warrant that all data provided by you
                      is accurate.
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h2>We may make changes to these Terms and Conditions</h2>
            <ol>
              <li>
                <p>
                  We may amend these Terms and Conditions from time to time
                  without notification to you. Every time you wish to use our
                  site, please check these Terms and Conditions to ensure you
                  understand the terms that apply at that time.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>The goods/services described on our website</h2>
            <ol>
              <li>
                <p>
                  Our website is a &lsquo;beta&rsquo; version which is for
                  testing purposes only. We make it available to ascertain and
                  analyse market demand for the goods and/or services that
                  feature on this website. Since this is a beta test version, it
                  is not currently possible to receive any of the goods or
                  services which feature on the website.
                </p>
              </li>
              <li>
                <p>
                  By filling out the form on this website you are registering
                  your interest in becoming a beta tester. You are not
                  purchasing, or offering to purchase, the goods and/or services
                  that our site relates to, nor does our site constitute a
                  contractual offer to provide any goods or services.
                </p>
              </li>
              <li>
                <p>
                  The goods and/or services that feature on this website are at
                  the developmental stage only and may never become commercially
                  available. We are, therefore, in no way obliged to contact you
                  about, or provide you with such goods and/or services.
                </p>
              </li>
              <li>
                <p>
                  If the goods and/or services are commercially released, we
                  will use reasonable efforts to notify you of such release and
                  to advise you of any further details that may be relevant.
                  This is also subject to our Privacy Policy In this respect,
                  please also see the clause headed&nbsp;&lsquo;OUR
                  RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU&rsquo;.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>We may change, suspend or withdraw our site</h2>
            <ol>
              <li>
                <p>
                  We may update and change our site from time to time to reflect
                  changes to the goods and/or services featured, our
                  users&rsquo; needs and our business priorities.
                </p>
              </li>
              <li>
                <p>
                  Our site is made available free of charge. We do not guarantee
                  that our site, or any content on it, will always be available
                  or be uninterrupted. We may suspend or withdraw or restrict
                  the availability of all or any part of our site for business
                  and operational reasons.
                </p>
              </li>
              <li>
                <p>
                  You are also responsible for ensuring that all persons who
                  access our site through your internet connection are aware of
                  these Terms and Conditions of use and other applicable terms
                  and conditions, and that they comply with them.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>How you may use material on our site</h2>
            <ol>
              <li>
                <p>
                  We own or have a licence to use all intellectual property
                  rights in our site, and in the material published on it. Those
                  works are protected by copyright laws and treaties around the
                  world. All such rights are reserved.
                </p>
              </li>
              <li>
                <p>
                  You may print off one copy, and may download extracts, of any
                  page(s) from our site for your personal use and you may draw
                  the attention of others within your organisation to content
                  posted on our site.
                </p>
              </li>
              <li>
                <p>
                  You must not modify the paper or digital copies of any
                  materials you have printed off or downloaded in any way, and
                  you must not use any illustrations, photographs, video or
                  audio sequences or any graphics separately from any
                  accompanying text.
                </p>
              </li>
              <li>
                <p>
                  Our status (and that of any identified contributors) as the
                  authors of content on our site must always be acknowledged.
                </p>
              </li>
              <li>
                <p>
                  You must not use any part of the content on our site for
                  commercial purposes without obtaining a licence to do so from
                  us or our licensors.
                </p>
              </li>
              <li>
                <p>
                  If you print off, copy or download any part of our site in
                  breach of these Terms and Conditions, your right to use our
                  site will cease immediately and you must, at our option,
                  return or destroy any copies of the materials you have made.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Do not rely on information on this site</h2>
            <ol>
              <li>
                <p>
                  The content on our site is provided for general information
                  only. It is not intended to amount to advice on which you
                  should rely. You must obtain professional or specialist advice
                  before taking, or refraining from, any action on the basis of
                  the content on our site.
                </p>
              </li>
              <li>
                <p>
                  Although we make reasonable efforts to update the information
                  on our site, we make no representations, warranties or
                  guarantees, whether express or implied, that the content on
                  our site is accurate, complete or up to date.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Acceptable Use Policy</h2>
            <ol>
              <li>
                <p>
                  You may use the site only for lawful purposes. You may not use
                  the site in any manner which:
                </p>
                <ol>
                  <li>
                    <p>breaches any applicable local or national law;</p>
                  </li>
                  <li>
                    <p>
                      is unlawful or fraudulent, or has any unlawful or
                      fraudulent purpose or effect;
                    </p>
                  </li>
                  <li>
                    <p>
                      we consider, in our absolute discretion, is harmful,
                      threatening, defamatory, obscene, infringing, harassing or
                      racially or ethically offensive; or
                    </p>
                  </li>
                  <li>
                    <p>
                      depicts sexually explicit images or promotes violence.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>You shall not, and shall not permit any person to:</p>
                <ol>
                  <li>
                    <p>
                      except to the extent expressly permitted under these Terms
                      and Conditions, attempt to copy, modify, tamper with,
                      duplicate, re-sell, create derivative works from, mirror,
                      republish, download, display, transmit, or distribute all
                      or any portion of our site in any form or media by any
                      means;
                    </p>
                  </li>
                  <li>
                    <p>
                      attempt to reverse compile, disassemble, reverse engineer
                      or otherwise reduce to human-perceivable form all or any
                      part of our site;
                    </p>
                  </li>
                  <li>
                    <p>
                      remove any mark, logo, trade name, copyright notice and/or
                      any other proprietary notice (as applicable) belonging to
                      us from our site;
                    </p>
                  </li>
                  <li>
                    <p>
                      access all or any part of our site in order to build a
                      product or service which competes with our site;
                    </p>
                  </li>
                  <li>
                    <p>use our site to provide services to third parties;</p>
                  </li>
                  <li>
                    <p>
                      procure or assist in the sending of any unsolicited or
                      unauthorised advertising or promotional material or any
                      other form of similar solicitation (spam); or
                    </p>
                  </li>
                  <li>
                    <p>
                      do or permit to be done any act or omission in relation to
                      use of this site which does or may adversely materially
                      affect our reputation, goodwill or image.
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h2>Our responsibility for loss or damage suffered by you</h2>
            <ol>
              <li>
                <p>Whether you are a consumer or a business user:</p>
                <ol>
                  <li>
                    <p>
                      We do not exclude or limit in any way our liability to you
                      where it would be unlawful to do so. This includes
                      liability for death or personal injury caused by our
                      negligence or the negligence of our employees, agents or
                      subcontractors and for fraud or fraudulent
                      misrepresentation.
                    </p>
                  </li>
                  <li>
                    <p>
                      Different limitations and exclusions of liability will
                      apply to liability arising as a result of the supply of
                      any products and/or services to you, which will be set out
                      in a separate agreement between you and us, (the
                      &ldquo;Additional T&amp;Cs&rdquo;). Any of our obligations
                      regarding any goods or service made available to you under
                      any Additional T&amp;Cs shall be governed solely by the
                      Additional T&amp;Cs.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>If you are a business user:</p>
                <ol>
                  <li>
                    <p>
                      We exclude all implied conditions, warranties,
                      representations or other terms that may apply to our site
                      or any content on it.
                    </p>
                  </li>
                  <li>
                    <p>
                      We will not be liable to you for any loss or damage,
                      whether in contract, tort (including negligence), breach
                      of statutory duty, or otherwise, even if foreseeable,
                      arising under or in connection with:i) use of, or
                      inability to use, our site; orii) use of or reliance on
                      any content displayed on our site.
                    </p>
                  </li>
                  <li>
                    <p>
                      In particular, we will not be liable for:i) loss of
                      profits, sales, business, or revenue;ii) business
                      interruption; iii) loss of anticipated savings;iv) loss of
                      business opportunity, goodwill or reputation; or v) any
                      indirect or consequential loss or damage.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>If you are a consumer user:</p>
                <ol>
                  <li>
                    <p>
                      Please note that we only provide our site for domestic and
                      private use. You agree not to use our site for any
                      commercial or business purposes, and we have no liability
                      to you for any loss of profit, loss of business, business
                      interruption, or loss of business opportunity.
                    </p>
                  </li>
                  <li>
                    <p>
                      If defective digital content that we have supplied,
                      damages a device or digital content belonging to you and
                      this is caused by our failure to use reasonable care and
                      skill, we will either repair the damage or pay you
                      compensation.
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <h2>
              We are not responsible for viruses and you must not introduce them
            </h2>
            <ol>
              <li>
                <p>
                  We do not guarantee that our site will be secure or free from
                  bugs or viruses.
                </p>
              </li>
              <li>
                <p>
                  You are responsible for configuring your information
                  technology, computer programmes and platform to access our
                  site. You should use your own virus protection software.
                </p>
              </li>
              <li>
                <p>
                  You must not misuse our site by knowingly introducing viruses,
                  trojans, worms, logic bombs or other material that is
                  malicious or technologically harmful. You must not attempt to
                  gain unauthorised access to our site, the server on which our
                  site is stored or any server, computer or database connected
                  to our site. You must not attack our site via a
                  denial-of-service attack or a distributed denial-of service
                  attack. By breaching this provision, you would commit a
                  criminal offence under the Computer Misuse Act 1990. We will
                  report any such breach to the relevant law enforcement
                  authorities and we will co-operate with those authorities by
                  disclosing your identity to them. In the event of such a
                  breach, your right to use our site will cease immediately.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Linking to our site</h2>
            <ol>
              <li>
                <p>
                  You may link to our website, provided you do so in a way that
                  is fair and legal and does not damage our reputation or take
                  advantage of it, but you must not establish a link in such a
                  way as to suggest any form of association, approval or
                  endorsement on our part where none exists.
                </p>
              </li>
              <li>
                <p>
                  You must not establish a link to our site in any website that
                  is not owned by you.
                </p>
              </li>
              <li>
                <p>
                  Our site must not be framed on any other site, nor may you
                  create a link to any part of our site other than the home
                  page. We reserve the right to withdraw linking permission
                  without notice. The website from which you are linking must
                  comply in all respects with the content standards set out in
                  these Terms and Conditions.
                </p>
              </li>
              <li>
                <p>
                  If you wish to link to or make any use of content on our site
                  other than that set out above, please contact{' '}
                  <a href="mailto:hello@zero-1.co">hello@zero-1.co</a>.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Which country&rsquo;s laws apply to any disputes?</h2>
            <ol>
              <li>
                <p>
                  If you are a consumer, please note that these Terms and
                  Conditions, their subject matter and their formation, are
                  governed by English law. You and we both agree that the courts
                  of England will have exclusive jurisdiction.
                </p>
              </li>
              <li>
                <p>
                  If you are a business, these Terms &nbsp;and Conditions, their
                  subject matter and their formation (and any non-contractual
                  disputes or claims) are governed by English law. We both agree
                  to the exclusive jurisdiction of the courts of England.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>
              These Terms and Conditions were last updated on 21/AUG/2018.
            </h2>
          </li>
        </ol>
      </LegalContent>
    </Layout>
  );
};

export default Terms;

import styled from 'styled-components';

interface IProps {
  background?: string;
  fontFamily?: string;
  fontSize?: string;
}

export const Inner = styled.div`
  padding: 1rem;

  /* stylelint-disable */
  .title {
    font: 400 var(--type-7) var(--font-mark-pro-medium);

    &:not(:first-child) {
      margin-top: 3rem;
    }
  }

  .items {
    list-style: none;
    margin: 0 0 0 -2rem;
  }

  .horizontal-items {
    display: flex;
    flex-wrap: wrap;
  }

  .vertical-items {
    display: block;
  }

  .item {
    display: block;
    padding: 2rem 0 0 2rem;
  }

  .caption {
    display: block;
  }
  /* stylelint-enable */
`;

export const LegalContent = styled.section`
  max-width: 960px;
  padding: var(--x4-space) var(--x2-space);
  margin: var(--x7-space) auto 0;

  & p {
    margin: 1rem 0;
  }

  & ul,
  & ol {
    margin: 1rem 0 1rem 1.5rem;
  }
`;

export const Tile = styled.div`
  background: ${(props: IProps) => props.background || 'transparent'};
  height: 4rem;
  width: 4rem;
`;
